import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const HeaderSection = styled.header`
  padding: 2rem 0;
  font-size: 1.15rem;
  nav {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .project-type {
    color: var(--light-black);
    display: inline-block;
    border: 2px solid var(--main-accent-color);
    padding: 0.5rem 1.25rem;
    @media (max-width: 600px) {
      display: none;
    }
  }
`

const Header = ({ category, link }) => (
  <HeaderSection>
    <motion.nav
      className="smaller-container"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 1 }}
    >
      <Link to="/#portfolio" activeStyle={{ display: "none" }}>
        ← Back to Portfolio Gallery
      </Link>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer nofollow"
        className="project-type"
      >
        {category}
      </a>
    </motion.nav>
  </HeaderSection>
)

export default Header
