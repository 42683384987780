import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        keywords={[`Bruno Brito`, `portfolio`, `projects`, frontmatter.title]}
      />
      <Header category={frontmatter.category} link={frontmatter.url} />
      <div className="container">
        <motion.a
          href={frontmatter.url}
          target="_blank"
          rel="noopener noreferrer nofollow"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.25 }}
        >
          <Img
            fluid={frontmatter.featuredImage.childImageSharp.fluid}
            alt={frontmatter.title}
            style={{ boxShadow: "3px 3px 6px 0px rgba(173, 161, 173, 1)" }}
          />
        </motion.a>
      </div>
      <div className="smaller-container">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.65 }}
        >
          {frontmatter.title}
        </motion.h1>
        <motion.div
          className="project-description"
          dangerouslySetInnerHTML={{ __html: html }}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.75 }}
        />
        <motion.div
          className="tech-stack"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <p>Technology / Frameworks / Skills used:</p>
          <ul>
            {frontmatter.tech.map((tech, index) => (
              <li key={index}>{tech}</li>
            ))}
          </ul>
        </motion.div>
        <motion.div
          className="view-project"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <a
            href={frontmatter.url}
            target="_blank"
            rel="noopener noreferrer nofollow"
            className="btn"
          >
            Visit
          </a>
        </motion.div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        category
        tech
        url
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
